.dashboard {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* Pe ecrane mici (1 coloană) */
  gap: 1rem; 
  margin-bottom: 2rem; 
}

@media (min-width: 768px) {
  .dashboard {
      grid-template-columns: repeat(2, 1fr); /* Pe ecrane de dimensiuni medii (2 coloane) */
  }
}

@media (min-width: 1024px) {
  .dashboard {
      grid-template-columns: repeat(4, 1fr); /* Pe ecrane mari (4 coloane) */
  }
}
