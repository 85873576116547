@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");



:root {
  --yellow: rgb(240, 243, 247);
  --orange: rgb(253, 253, 255);
  --black: #242d49;
  --gray: rgba(36, 45, 73, 0.65);
  --profileShadow: 0px 4px 17px 2px rgba(0, 0, 0, 0.25);
  --hrColor: #cfcdcd;
  --cardColor: rgb(0, 21, 41);
  --buttonBg: linear-gradient(98.63deg, #2123b1 0%, #ca3a3a 100%);
  --inputColor: rgba(255, 255, 255, 0.836);
  --photo: #4cb256;
  --video: #4a4eb7;
  --location: rgb(0, 21, 41);
  --shedule: rgb(176, 194, 211);

  --BGCOLOR: #0f172a;
  --BORDER-COLOR: #fff;
  --COLOR: #fff;
  --ERROR: firebrick;
  --INACTIVE: lightgray;
  --LINK-COLOR: whitesmoke;
  --LINK-HOVER: rgba(245, 245, 245, 0.9);
  --NOTE-BGCOLOR: hsla(240, 64%, 27%, 0.65);
  --STATUS-COMPLETE: limegreen;
  --STATUS-OPEN: firebrick;
  --TABLE-BGCOLOR: #fff;
  --TABLE-COLOR: #000;
  --INPUT-INVALID: #f00;
}

.App {
  /* overflow: hidden; */
  color: var(--black);
  background: #f3f3f3;
}

/* blur */
.blur {
  position: absolute;
  width: 22rem;
  height: 14rem;
  border-radius: 50%;
  background: #a6ddf0;
  filter: blur(72px);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  border-radius: 0.5rem;
  background: var(--buttonBg);
  transition: all 100ms ease-out;
}

.button:hover {
  color: var(--orange);
  border: 2px solid var(--orange);
  cursor: pointer;
  background: transparent;
}

.button:disabled {
  background: silver;
  pointer-events: none;
}

::-webkit-scrollbar {
  display: none;
}

/* Form Styling */
.infoForm {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.7rem;
}

.infoInput {
  border: none;
  outline: none;
  background-color: var(--inputColor) !important;
  border-radius: 10px;
  padding: 8px;
  flex: 1;
}

.infoForm > div {
  display: flex;
  gap: 1rem;
  height: 2rem;
  width: 90%;
}

.infoButton {
  width: 7.2rem;
  height: 2.2rem;
  align-self: flex-end;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* || VARIABLES */

/* || UTILITY CLASSES */
.errmsg {
  display: flex;
  color: var(--ERROR);
  padding: 0.25em;
  margin-bottom: 0.5em;
  justify-content: center;
  align-items: center;
}

.errmsg a:any-link {
  color: var(--ERROR);
  text-decoration: underline;
}

.nowrap {
  white-space: nowrap;
}

.icon-button {
  width: 48px;
  height: 48px;
  font-size: 2.5rem;
  background-color: transparent;
  border: none;
  color: var(--COLOR);
  display: grid;
  place-content: center;
}

.icon-button:hover,
.icon-button:focus-visible {
  transform: scale(1.2);
}

.icon-button[disabled] {
  visibility: hidden;
}

/* || GENERAL STYLES */

body {
  font-family: "Nunito", sans-serif;
  min-height: 100vh;
  background-color: var(--BGCOLOR);
  color: var(--COLOR);
  background-size: cover;
  background-position: top right;
  background-blend-mode: multiply;
}

#root {
  /* React root div */
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
}

a {
  text-decoration: none;
}

a:any-link {
  color: var(--LINK-COLOR);
}

a:hover,
a:focus-visible {
  color: var(--LINK-HOVER);
}

/* || PAGES */

.public,
.welcome {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.public {
  padding: 1em;
  flex-grow: 1;
}

.public__main,
.login {
  flex-grow: 1;
  border-top: 2px solid var(--BORDER-COLOR);
  border-bottom: 2px solid var(--BORDER-COLOR);
  padding: 1em 0;
}

.public__addr {
  margin-top: 1em;
}

.welcome a::before {
  content: "➜ ";
}

/* || DASH HEADER, CONTAINER & FOOTER */

.dash-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--BGCOLOR);
  padding: 0 0.5em;
  border-bottom: 1px solid var(--BORDER-COLOR);
}

.dash-header__container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.dash-header__container--small {
  max-width: 800px;
}

.dash-header__nav {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  gap: 0.5em;
}

.dash-container {
  padding: 0.75em 0.5em 0.5em;
  flex-grow: 1;
}

.dash-footer {
  font-size: 1rem;
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: var(--BGCOLOR);
  padding: 0.5em;
  border-top: 1px solid var(--BORDER-COLOR);
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  gap: 1em;
}

.dash-footer__button {
  font-size: 1rem;
  width: auto;
  height: auto;
}

/* || TABLES - GENERAL */

/* thead,
tbody,
tr {
  display: contents;
} */

.table {
  font-size: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  color: var(--TABLE-COLOR);
  gap: 0.1em;
}

.table__button {
  padding: 0.25em;
  font-size: 1.5rem;
  color: var(--TABLE-COLOR);
}

.table__thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.table__th,
.table__cell {
  background-color: var(--TABLE-BGCOLOR);
  text-align: left;
  border: 1px solid var(--TABLE-COLOR);
  padding: 0.5em;
}

.table__cell:last-child {
  display: grid;
  place-content: center;
}

.table__cell--inactive {
  background-color: var(--INACTIVE);
}

/* ||  NOTE */

.note__created,
.note__updated,
.note__username {
  display: none;
}

.note__status {
  font-weight: bold;
}

.note__status--open {
  color: var(--STATUS-OPEN);
}

.note__status--completed {
  color: var(--STATUS-COMPLETE);
}

/* || FORM - GENERAL */

.form {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.75em;
  max-width: 800px;
}

.form__checkbox-container {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 0.5em;
}

.form__persist {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.2em;
}

.form__title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form__input,
.form__submit-button {
  padding: 0.5em;
  border-radius: 15px;
}

.form__input--text {
  min-height: 150px;
}

.form__input--incomplete {
  border: 1px solid var(--INPUT-INVALID);
  outline: 1px solid var(--INPUT-INVALID);
}

.form__checkbox {
  width: 18px;
  height: 18px;
}

.form__select {
  width: fit-content;
  padding: 0.25em;
}

.form__action-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5em;
  position: absolute;
  right: 0.5em;
}

.form__row {
  display: flex;
  flex-flow: row nowrap;
  gap: 2em;
}

.form__divider {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5em;
}

.inputSongs{
  background-color: #27272A; /* Echivalent pentru bg-zinc-800 */
  border: 1px solid #3F3F46; /* Echivalent pentru border-zinc-700 */
  color: #A1A1AA;
}

.inputSongs:hover, .inputSongs:focus {
  background-color: #27272A;
}

.inputSongs::placeholder {
  color: #A1A1AA; /* Exemplu: culoare gri zinc-400 */
}

.butonSongs{
 width: 100%;
 margin-top: 1rem;
}

.butonSongs:disabled {
  background-color: #ffffff; /* Fundal alb */
  color: #000000; /* Text negru */
  cursor: not-allowed; /* Indicator pentru starea dezactivată */
  opacity: 1; /* Dezactivează transparența implicită */
  border: 1px solid #ccc; /* (Opțional) Adaugă o margine vizibilă */
}

@media screen and (max-width: 400px) {
  h1 {
    font-size: 2rem;
  }

  .dash-header__nav {
    gap: 0;
  }

  .icon-button {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 768px) {
  body {
    font-size: 1.25rem;
  }

  .dash-header {
    padding: 0 1em;
  }

  .dash-container {
    padding: 1em;
  }

  .table {
    font-size: 1.5rem;
  }

  .table--notes {
    grid-template-columns: repeat(3, 1fr) 2fr repeat(2, 1fr);
  }

  .note__created,
  .note__updated,
  .note__username {
    display: block;
  }

  .form__action-buttons {
    position: static;
  }
}
