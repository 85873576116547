.card {
    background-color: rgba(39, 39, 42, 0.5);
    border: 1px solid rgba(63, 63, 70, 0.5);
    transition: background-color 0.2s;
    color: white;
    cursor: pointer;
  }
  
  .card:hover {
    background-color: rgba(39, 39, 42, 0.8);
  }

.cardDiv {
    display: flex; 
    align-items: center; 
    gap: 1rem; 
}

.labelParagraf {
    font-size: 0.875rem;
    color: #a1a1aa;
}

.valueParagraf {
    font-size: 1.5rem; 
    font-weight: 700; 
}

