.tableRow:hover {
    background-color: rgba(39, 39, 42, 0.5); /* Culoarea exactă pentru `zinc-800` cu opacitate 50% */
  }
  
.tableCell1 {
    font-weight: 500;
  }

  .tableCell2 {
    display: inline-flex;
    align-items: center;
    gap: 4px; /* echivalent cu gap-1 în Tailwind */
    color: #9ca3af; /* echivalent cu text-zinc-400 în Tailwind */
  }
  
  .btnsActions {
    display: flex;
    gap: 8px; /* echivalent cu gap-2 în Tailwind */
    justify-content: flex-end;
  }
  


  