.songs-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  /* background: #242424; */
  /* border-radius: 8px; */
  overflow: hidden;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); */
  
}

.songs-table th,
.songs-table td {
  padding: 0.5rem;
  text-align: left;
  
}

.songs-table th {
  /* background: #1a1a1a; */
  font-weight: 600;
  font-size: 0.9rem;
  letter-spacing: 0.05em;
  color: #777;
}

.songs-table tr {
  /* background: #2a2a2a; */
  transition: all 0.3s ease;
  position: relative;
}

.songs-table tr:hover {
  background: #333;
}

.songs-table tr.dragging {
  opacity: 0.5;
  background: #444;
}

.songs-table tr.drop-preview {
  background: transparent;
  height: 0;
  padding: 0;
  overflow: hidden;
  border-top: 2px solid rgba(39, 39, 42, 0.5);
  transition: all 0.2s ease;
}

.songs-table tr.drop-preview td {
  padding: 0;
  border: none;
}

.songs-table tr.drop-preview.active {
  height: 2rem;
  background: rgba(63, 63, 66, 0.5);
}

.songs-table td.number-cell {
  text-align: right;
  font-variant-numeric: tabular-nums;
}

.songs-table td.center-cell {
  text-align: center;
}